




































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import workflowModule from '@/store/modules/workflowModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
	TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    TasqsMobile: () =>  getComponent('tasqs/TasqDetailMobile'),
  }
})
export default class WorkflowItem extends mixins(DataLoading) {

  showWorkflowMenu = false

  showModal = false;

windowWidth = window.innerWidth;

  toggleWorkflowMenu() {
	  this.showWorkflowMenu = !this.showWorkflowMenu
  }

  closeDialog(){
    this.showModal = false;
  }

  closeWorkflowSidebar() {
	  this.$emit("closeComponentEvent", "")
  }

  get workflowTasqs(): any[] {
	  var workflows: any[] = []
	  for (var x = 0; x < tasqsListModule.tasqList.length; x++) {
		  if (tasqsListModule.tasqList[x].predictionType.toLowerCase() == "workflow") {
			  if (tasqsListModule.tasqList[x].workflowDetailsID == this.workflow.id) {
			  	workflows.push(tasqsListModule.tasqList[x])
			  }

		  }
	  }
	  return workflows
  }

  goToTasqPage(tasq) {
     tasqsListModule.setActiveTasq(tasq.id || '');
    tasqsListModule.setKanbanSelectedTasq(tasq?.id || '');
	this.showModal = true;
    // tasqsListModule.setActiveTasq(tasq?.id || '');
  }

  get isMobile() {
     return this.windowWidth < 769;
   }

   mounted() {
     this.$nextTick(() => {
       window.addEventListener('resize', this.onResize);
     });
   }

    beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    tasqsListModule.setActivePage('');
    tasqsListModule.setActiveTasq('')
    tasqsListModule.setKanbanSelectedTasq('')
  }

   onResize() {
     this.windowWidth = window.innerWidth;
   }

  async created() {
	tasqsListModule.setActivePage('Workflow')
	  await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
	  this.stopDataLoading();
  }

	 @Watch('kanbanTasq')
  async onkanbanTasqChange(id: string) {
    if(id){
      console.log(id)
      this.showModal = true;
    }else {
      this.showModal = false;
    }
   
  }
  get workflow() {

    return workflowModule.activeWorkflow as any;
  }



  get heading() {
    if (!this.workflow.result) return '';
    let finalText = '';
    this.workflow.conditions.forEach((condition, ci) => {
      if ((condition.condition || {}).value === 'STATE_OF_WELL') {
        const wellStateText = condition.recurring
          ? `current well state is <b>${(condition.conditionValue || {}).text}</b> and has been ${(condition.conditionValue || {}).text}
               <b>${(condition.recurring_data || {}).times} time(s)</b> out of
               <b>${(condition.recurring_data || {}).duration} ${(condition.recurring_data || {}).durationValue}(s)</b>`
          : `current well state is <b>${(condition.conditionValue || {}).text}</b>`;
        // eslint-disable-next-line
        finalText += `${ci <= 0 ? ' if' : (condition.connector ? ' and ' : ' or ')} ${wellStateText}`;
      } else {
        // eslint-disable-next-line
        finalText += `  <span>${ci <= 0 ? '' : `&nbsp;${condition.connector ? 'and' : 'or'}`}</span>
          <span>&nbsp;if&nbsp;</span><span style="color: rgba(1,236,220,255)" class="underline">${condition.conditionValue.text || ''}
          ${(condition.operationalModifier.text === 'Value' || !condition.operationalModifier.value) ? '' : condition.operationalModifier.text}
          ${condition.valueModifier.text === 'Greater than' || condition.valueModifier.text === 'Less than' ? 'is' : ''}
          ${condition.valueModifier.text} ${(condition.valueModifier.value === 'DECREASES_AT'
          || condition.valueModifier.value === 'INCREASES_AT') ? `${condition.value}%` : (condition.value || '')} for <b>${condition.howLong} hours</b>
          </span>
        `;
      }
    });
    finalText = `<span>A tasq will be assigned to <span style="color: rgba(1,236,220,255)" class="underline">${this.workflow.result.to.text}</span></span>
      ${finalText.toLowerCase()} for ${!this.workflow.result.source.length ? 'all wells' : this.workflow.result.source}.`;
    return finalText;
  }

  confirmDeleteOpen() {
    this.$dialog.confirm('Delete workflow?', 'This action cannot be undone.', 'info')
      .then(async (result) => {
        if (result.isOk) {
         this.startDataLoading();
         await workflowModule.deleteWorkflow(this.workflow)
         this.close();
         this.stopDataLoading();
        }
      })
  }

  editWorkflow() {
    this.$emit('change-component', 'update-workflow')
  }

  close() {
    this.$emit('close-left-component');
  }
}
